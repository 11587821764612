<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="contracts"
                          columnsPrefix="contract.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.contracts"
                          :actions-list="getActions()"
                >

                    <template #cell(id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('contract-modal', {contractId:row.item.id}, refreshTable)">{{
                                row.item.id
                            }}</a>
                    </template>
                    <template #cell(file)="row">
                        <a href="#" @click.prevent="contractUrl(row.item.file)">{{ row.item.file }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item v-if="row.item._edit"
                                             @click="$root.$children[0].openModal('contract-modal', {contractId:row.item.id}, refreshTable)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.item._delete"
                                             @click="deleteContract(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'ContractSettings',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('ContractSettings', ['getContractsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'ContractSettings/fetchContracts', 'getContractsTable');
        },
        ctxBaseParams() {
            return {
                'startDate': this.dateStart,
                'endDate': this.dateEnd,
            }
        },
        contractUrl(file) {
            this.$root.openDocument(file, 'contracts')
        },
        deleteContract(id) {
            this.$removeConfirm('ContractSettings/deleteContract', {
                contractId: id
            }, this.refreshTable)
        },
        deleteSelected() {
            this.$removeConfirm('ContractSettings/deleteContracts', this.selected.map(row => row.id), this.refreshTable)
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.createContract'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('contract-settings', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('contract-modal', {}, this.refreshTable)
                    },
                },
                {
                    title: this.$t('common.button.delete'),
                    icon: 'trash',
                    hide: !this.$root.hasAccess('contract-settings', 'delete'),
                    click: () => {
                        this.deleteSelected();
                    },
                }
            ]
        }
    }
}
</script>